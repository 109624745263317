import './reset.css';
import './App.css';
import Main from './components/MainComponent'

function App() {
  return (
    <div className="App">
        <Main/>
    </div>
  );
}

export default App;

